$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        cache: false
    });

    // Mobile Safari in standalone mode
    if (("standalone" in window.navigator) && window.navigator.standalone) {

        // If you want to prevent remote links in standalone web apps opening Mobile Safari, change 'remotes' to true
        var noddy, remotes = false;

        document.addEventListener('click', function (event) {

            noddy = event.target;

            // Bubble up until we hit link or top HTML element. Warning: BODY element is not compulsory so better to stop on HTML
            while (noddy.nodeName !== "A" && noddy.nodeName !== "HTML") {
                noddy = noddy.parentNode;
            }

            if ('href' in noddy && noddy.href.indexOf('http') !== -1 && (noddy.href.indexOf(document.location.host) !== -1 || remotes)) {
                event.preventDefault();
                document.location.href = noddy.href;
            }

        }, false);
    }

    $('.matchheight').matchHeight();

    $("#lightgallery").lightGallery();

    console.log($('.video-background').outerHeight());

    if ($('#header_video').length) {
        var navheight = $('.navigation').outerHeight();
        $('#header_video').css('margin-top', navheight+'px');

//        alert(navheight);
    }


    /*
     * Smooth Scroll
     */
    $('.scroll').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                return false;
            }
        }
    });

    /*
     * Apply Affix
     */
    $('.navbar').affix({
        offset: {
            top: 40
        }
    })

    /*
     * Flash messages
     */
    $('#flash-overlay-modal').modal();
    /*
     * Flash messages auto clear
     */
    $('.modal-auto-clear').on('shown.bs.modal', function () {
        // if data-timer attribute is set use that, otherwise use default (7000)
        var timer = $(this).data('timer') ? $(this).data('timer') : 15000;
        $(this).delay(timer).fadeOut(200, function () {
            $(this).modal('hide');
        });
    })


    /*
     * Initiate Swiper
     */
    var mySwiper = new Swiper('.swiper-container', {
        autoplay: 7000,
        speed: 600,
        loop: true,
        parallax: true,
        effect: 'fade',

        // If we need pagination
        pagination: '.swiper-pagination',
        paginationClickable: true,

        //// Navigation arrows
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        //
        //// And if we need scrollbar
        //scrollbar: '.swiper-scrollbar',
    })


    /*
     * Initiate popovers and tooltips
     */
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip({html: true});

    /*
     * Apply Matchheight
     */

    $(".ellipsis").dotdotdot({

        watch: "window",
        /*	The text to add as ellipsis. */
        ellipsis: '... ',
        /*	How to cut off the text/html: 'word'/'letter'/'children' */
        wrap: 'word',
        /*	Wrap-option fallback to 'letter' for long words */
        fallbackToLetter: true,
        /*	jQuery-selector for the element to keep and put after the ellipsis. */
        after: null,
        /*	Whether to update the ellipsis: true/'window' */
        watch: false,
        /*	Optionally set a max-height, can be a number or function.
         If null, the height will be measured. */
        height: null,
        /*	Deviation for the height-option. */
        tolerance: 0,
        /*	Callback function that is fired after the ellipsis is added,
         receives two parameters: isTruncated(boolean), orgContent(string). */
        callback: function (isTruncated, orgContent) {
        },
        lastCharacter: {
            /*	Remove these characters from the end of the truncated text. */
            remove: [' ', ',', ';', '.', '!', '?'],
            /*	Don't add an ellipsis if this array contains
             the last character of the truncated text. */
            noEllipsis: []
        }
    });


    /*
     * Apply Scrollreveal
     */
    window.sr = ScrollReveal({
        origin: 'bottom',
        delay: 100,
        viewFactor: 0.3,
        mobile: true,
    });
    sr.reveal('.reveal');


    function log(msg)
    {
        try {
            console.log(msg);
        } catch (e) {
        }
    }

});